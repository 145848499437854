






















import { Prop, Component, Vue } from 'vue-property-decorator';
import LogoSVG from '@/assets/img/logo.svg';

@Component({
  components: { LogoSVG }
})
export default class AdsBlocked extends Vue {
  @Prop({}) data!: any;
  private redirect = 'home';

  private created() {
    this.redirect =
      this.$route.hash && this.$route.hash.trim().length
        ? this.$route.hash.substr(1, this.$route.hash.length)
        : 'home';
  }
}
